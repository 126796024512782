import { TranslatableStringInterface } from "interfaces/translatable-string.interface";
import wsConfig from "../../webservice-config.json";
export const translationHelper = (
    translatable: String | TranslatableStringInterface | undefined,
    language: string
): string => {
    if (translatable === null || translatable === undefined) {
        return "";
    }
    if (typeof translatable === "string") {
        return translatable;
    }
    if (typeof translatable === "object") {
        translatable = translatable as TranslatableStringInterface;
        if (translatable[language]) {
            return translatable[language] || "";
        }
        return translatable[wsConfig.webservice.default_language || "fi"] || "";
    }

    return "";
};
